























































import { Component, Vue, Watch } from 'vue-property-decorator';
import Item from '../components/Item.vue';
import axios from 'axios';
const api = axios.create({
    baseURL: 'https://cgp-app.com/pwa',
    timeout: 10000,
    headers: {
       'accept': 'application/json',
    },
});
import {filter} from 'lodash';
@Component({
    components: {
        Item,
    },
})
export default class Home extends Vue {
    get countriesInRegion() {
        if (this.form.region) {
            return filter(this.countries, (country: any) => {
                return country.region && country.region.id === this.form.region;
            });
        }
        return this.countries;
    }

    get categoriesInCountry() {
        if (this.selectedCountry) {
            return filter(this.categories, (category: any) => {
                return category.countries.includes(this.selectedCountry);
            });
        }
        return this.categories;
    }
    private deferredPrompt: any = null;
    private visiblePrompt: boolean = false;
    private isLoading: boolean = false;
    private regions: any[] = [];
    private countries: any[] = [];
    private categories: any[] = [];
    private selectedCountry: string = '';
    private form: any = {
        region: '',
        country: '',
    };

    private options: any[] = [{
        value: 'Option1',
        label: 'Option1',
    }, {
        value: 'Option2',
        label: 'Option2',
    }, {
        value: 'Option3',
        label: 'Option3',
    }, {
        value: 'Option4',
        label: 'Option4',
    }, {
        value: 'Option5',
        label: 'Option5',
    }];
    @Watch('form.region')
    public onRegionChange() {
        this.form.country = '';
    }
    public addToHomescreen() {
        this.visiblePrompt = false;
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice
            .then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                this.deferredPrompt = null;
            });
    }

    private created() {
        const regions = new Promise((resolve, reject) => {
            api.get('/region')
                .then(({ data }) => {
                    this.regions = data;
                    resolve(data);
                });
        });
        const countries = new Promise((resolve, reject) => {
            api.get('/country')
                .then(({ data }) => {
                    this.countries = data;
                    resolve(data);
                });
        });
        const categories = new Promise((resolve, reject) => {
            api.get('/productCategory')
                .then(({ data }) => {
                    this.categories = data;
                    resolve(data);
                });
        });
        const promises: any[] = [
            regions,
            countries,
            categories,
        ];
        Promise.all(promises)
            .then(() => {
            })
            .catch(() => {
            });
    }

    private mounted() {
        this.bindListeners();
    }

    private setSelectedCountry() {
        this.isLoading = true;
        this.selectedCountry = this.form.country;
        setTimeout(() => {
            this.isLoading = false;

        }, 300);
    }

    private bindListeners() {

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            this.visiblePrompt = true;
        });
    }


}


















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    private logo: string = require('@/assets/logo.svg');
    private up: string = require('@/assets/up.svg');
    private goToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
}








































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {
    private logo: string = require('@/assets/logo.svg');
    private icn_menu: string = require('@/assets/icn_menu.svg');
    private windowSize: number = window.innerWidth;
    private open: boolean = false;
    private mounted() {
        window.addEventListener('resize', () => {
            this.windowSize = window.innerWidth;
        });
    }
}

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Item extends Vue {
    @Prop() public item!: any;
    private icn_view: string = require('@/assets/icn_view.svg');
    private hover: boolean = false;
}

Vue.config.productionTip = false;

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import './plugins/element.js';
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import Home from '@/views/Home.vue';
import '@/styles/main.scss';
import lineClamp from 'vue-line-clamp';

Vue.use(lineClamp);
Vue.component('vnavigation', Navigation);
Vue.component('vfooter', Footer);
Vue.component('vhome', Home);
Vue.prototype.$striptags = require('striptags');
new Vue({
    store,
    // render: (h) => h(App),
}).$mount('#app');

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then((registration) => {
            // console.log('SW registered: ', registration);
            // registration.pushManager.subscribe({userVisibleOnly: true})
            //     .then(() => {
            //         console.log('Subscribed to push messages');
            //     })
            //     .catch(() => {
            //         console.log('Couldn\'t subscribe to push messages');
            //     })
            // ;
        }).catch((registrationError) => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
